import type {
    VatNonChargeReasonResponse,
    CreateVatNonChargeReasonRequest,
} from '~/schemas'

const baseUrl = (companyId: string) =>
    `/companies/${companyId}/vat-non-charge-reasons`

export const vatNonChargeReasons = {
    create(
        companyId: string,
        createVatNonChargeReasonRequest: CreateVatNonChargeReasonRequest,
    ) {
        return apiClient<VatNonChargeReasonResponse>(baseUrl(companyId), {
            method: 'POST',
            body: createVatNonChargeReasonRequest,
        })
    },

    findAll(companyId: string) {
        return apiClient<VatNonChargeReasonResponse[]>(baseUrl(companyId))
    },

    findOne(companyId: string, vatNonChargeReasonId: string) {
        return apiClient<VatNonChargeReasonResponse>(
            `${baseUrl(companyId)}/${vatNonChargeReasonId}`,
        )
    },

    remove(companyId: string, vatNonChargeReasonId: string) {
        return apiClient<VatNonChargeReasonResponse>(
            `${baseUrl(companyId)}/${vatNonChargeReasonId}`,
            {
                method: 'DELETE',
            },
        )
    },
}
