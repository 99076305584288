import type {
    SummaryResponse,
    ExpenseSummaryResponse,
    RevenueSummaryResponse,
    SummaryFiltersQuery,
} from '~/schemas'

const baseUrl = (companyId: string) => `/companies/${companyId}/dashboard`

export const dashboard = {
    hasTransactions(companyId: string) {
        return apiClient<boolean>(`${baseUrl(companyId)}/has-transactions`)
    },

    summary(companyId: string, params?: SummaryFiltersQuery) {
        return apiClient<SummaryResponse>(`${baseUrl(companyId)}/summary`, {
            params,
        })
    },

    revenueSummary(companyId: string, params?: SummaryFiltersQuery) {
        return apiClient<RevenueSummaryResponse>(
            `${baseUrl(companyId)}/revenue-summary`,
            {
                params,
            },
        )
    },

    expenseSummary(companyId: string, params?: SummaryFiltersQuery) {
        return apiClient<ExpenseSummaryResponse>(
            `${baseUrl(companyId)}/expense-summary`,
            {
                params,
            },
        )
    },
}
