import type { SnackbarOptions } from '~/stores/snackbar'

type Options = Omit<SnackbarOptions, 'type' | 'text'>
type Snackbar = {
    [key in SnackbarOptions['type']]: (text: string, options?: Options) => void
}

export const $snackbar: Snackbar = {
    success(text: string, options?: Options) {
        useSnackbarStore().show({
            type: 'success',
            text,
            ...options,
        })
    },
    error(text: string, options?: Options) {
        useSnackbarStore().show({
            type: 'error',
            text,
            ...options,
        })
    },
    warning(text: string, options?: Options) {
        useSnackbarStore().show({
            type: 'warning',
            text,
            ...options,
        })
    },
    info(text: string, options?: Options) {
        useSnackbarStore().show({
            type: 'info',
            text,
            ...options,
        })
    },
}
