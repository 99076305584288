import revive_payload_client_Iq0QbmwxBT from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.3_@unocss+reset@_d3p5kb2tufs2fje6zlwiq2o3su/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_BzCfTXE4Rr from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.3_@unocss+reset@_d3p5kb2tufs2fje6zlwiq2o3su/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1iPYaECgoR from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.3_@unocss+reset@_d3p5kb2tufs2fje6zlwiq2o3su/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_LcOzjglWUM from "/opt/render/project/src/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.4_rollup@4.17.2_typescript@5.5.4_vue@3.4.27_typescript@5.5.4_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/render/project/src/apps/app/.nuxt/components.plugin.mjs";
import prefetch_client_krCNXPDXF7 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.3_@unocss+reset@_d3p5kb2tufs2fje6zlwiq2o3su/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_xboYlnx3Qj from "/opt/render/project/src/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@_3bqyzgqnsgivwdosgskj25mzum/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_0xxIX4MkAg from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.17.2/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_G1PfYScGCh from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_9aW5v9o0T3 from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+i18n@8.5.1_magicast@0.3.4_rollup@4.17.2_vue@3.4.27_typescript@5.5.4_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_f9ccKENjmn from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.16.3_@unocss+reset@_d3p5kb2tufs2fje6zlwiq2o3su/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _1_init_hVDiYuZhdY from "/opt/render/project/src/apps/app/plugins/1.init.ts";
import i18n_VfGcjrvSkj from "/opt/render/project/src/apps/app/plugins/i18n.ts";
import sentry_3AyO8nEfhE from "/opt/render/project/src/apps/app/plugins/sentry.ts";
import snackbar_G4WEaUIGl5 from "/opt/render/project/src/apps/app/plugins/snackbar.ts";
import zodErrors_YzNmqC0Kau from "/opt/render/project/src/apps/app/plugins/zodErrors.ts";
import plugin_auto_pageviews_client_EAx64ccgHL from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_magicast@0.3.4_rollup@4.17.2/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_Iq0QbmwxBT,
  unhead_BzCfTXE4Rr,
  router_1iPYaECgoR,
  plugin_vue3_LcOzjglWUM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_krCNXPDXF7,
  plugin_xboYlnx3Qj,
  plugin_client_0xxIX4MkAg,
  switch_locale_path_ssr_G1PfYScGCh,
  i18n_9aW5v9o0T3,
  chunk_reload_client_f9ccKENjmn,
  _1_init_hVDiYuZhdY,
  i18n_VfGcjrvSkj,
  sentry_3AyO8nEfhE,
  snackbar_G4WEaUIGl5,
  zodErrors_YzNmqC0Kau,
  plugin_auto_pageviews_client_EAx64ccgHL
]