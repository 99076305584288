import type {
    UnitResponse,
    CreateUnitRequest,
    UpdateUnitRequest,
} from '~/schemas'

const baseUrl = (companyId: string) => `/companies/${companyId}/units`

export const units = {
    create(companyId: string, createUnitRequest: CreateUnitRequest) {
        return apiClient<UnitResponse>(baseUrl(companyId), {
            method: 'POST',
            body: createUnitRequest,
        })
    },

    findAll(companyId: string) {
        return apiClient<UnitResponse[]>(baseUrl(companyId))
    },

    findOne(companyId: string, unitId: string) {
        return apiClient<UnitResponse>(`${baseUrl(companyId)}/${unitId}`)
    },

    update(
        companyId: string,
        unitId: string,
        updateUnitRequest: UpdateUnitRequest,
    ) {
        return apiClient<UnitResponse>(`${baseUrl(companyId)}/${unitId}`, {
            method: 'PUT',
            body: updateUnitRequest,
        })
    },

    remove(companyId: string, unitId: string) {
        return apiClient<UnitResponse>(`${baseUrl(companyId)}/${unitId}`, {
            method: 'DELETE',
        })
    },
}
