import type {
    AccountResponse,
    AccountFiltersQuery,
    AccountTreeResponse,
    CreateAccountRequest,
    UpdateAccountRequest,
} from '~/schemas'

const baseUrl = (companyId: string) => `/companies/${companyId}/accounts`

export const accounts = {
    findAll(
        companyId: string,
        params?: {
            filters?: AccountFiltersQuery
        },
    ) {
        return apiClient<AccountResponse[]>(baseUrl(companyId), {
            params: {
                ...params?.filters,
            },
        })
    },

    accountTree(companyId: string) {
        return apiClient<AccountTreeResponse>(
            `${baseUrl(companyId)}/account-tree`,
        )
    },

    createAccount(
        companyId: string,
        createAccountRequest: CreateAccountRequest,
    ) {
        return apiClient<AccountResponse>(baseUrl(companyId), {
            method: 'POST',
            body: createAccountRequest,
        })
    },

    updateAccount(
        companyId: string,
        accountId: string,
        updateAccountRequest: UpdateAccountRequest,
    ) {
        return apiClient<AccountResponse>(
            `${baseUrl(companyId)}/${accountId}`,
            {
                method: 'PUT',
                body: updateAccountRequest,
            },
        )
    },

    removeAccount(companyId: string, accountId: string) {
        return apiClient<AccountResponse>(
            `${baseUrl(companyId)}/${accountId}`,
            {
                method: 'DELETE',
            },
        )
    },
}
