import type { Locale } from '@prisma/client'
import type { Composer } from 'vue-i18n'

export default defineNuxtPlugin(async (nuxtApp) => {
    const authStore = useAuthStore()
    const i18n = nuxtApp.$i18n as Composer

    // update app locale on init to match user's saved locale
    if (authStore.user) {
        const userLocale = authStore.user.locale
        const i18nLocale = i18n.locale.value

        if (i18nLocale !== userLocale) {
            i18n.setLocale(userLocale)
        }
    }

    // update user's saved locale when app locale changes
    nuxtApp.hook('i18n:beforeLocaleSwitch', async ({ newLocale }) => {
        const locale = newLocale as Locale

        if (authStore.user) {
            try {
                await $api.me.selectLocale(locale)

                authStore.user.locale = locale
            } catch (error: any) {
                console.error(error)
                $sentry.captureException(error)
            }
        }
    })
})
