/**
 * Remove undefined and empty string values from object
 */
export function sanitizeObject(obj: Record<string, any>): Record<string, any> {
    const sanitizedEntries = Object.entries(obj).filter(
        ([_key, value]) => value !== undefined && value !== '',
    )

    return Object.fromEntries(sanitizedEntries)
}
