import type { TaxResponse, CreateTaxRequest, UpdateTaxRequest } from '~/schemas'

const baseUrl = (companyId: string) => `/companies/${companyId}/taxes`

export const taxes = {
    create(companyId: string, createTaxRequest: CreateTaxRequest) {
        return apiClient<TaxResponse>(baseUrl(companyId), {
            method: 'POST',
            body: createTaxRequest,
        })
    },

    findAll(companyId: string) {
        return apiClient<TaxResponse[]>(baseUrl(companyId))
    },

    findOne(companyId: string, taxId: string) {
        return apiClient<TaxResponse>(`${baseUrl(companyId)}/${taxId}`)
    },

    update(
        companyId: string,
        taxId: string,
        updateTaxRequest: UpdateTaxRequest,
    ) {
        return apiClient<TaxResponse>(`${baseUrl(companyId)}/${taxId}`, {
            method: 'PUT',
            body: updateTaxRequest,
        })
    },

    remove(companyId: string, taxId: string) {
        return apiClient<TaxResponse>(`${baseUrl(companyId)}/${taxId}`, {
            method: 'DELETE',
        })
    },
}
