import type {
    InvoiceResponse,
    CreateInvoiceRequest,
    UpdateInvoiceRequest,
    PaginatedInvoicesResponse,
    InvoiceFiltersQuery,
    InvoiceOrderQuery,
    SendEmailRequest,
    InvoiceStatus,
    DocumentLabel,
    PaginationQuery,
    FileType,
    ExportVariant,
} from '~/schemas'
import type { Locale } from '@prisma/client'

const baseUrl = (companyId: string) => `/companies/${companyId}/invoices`

export const invoices = {
    create(companyId: string, createInvoiceRequest: CreateInvoiceRequest) {
        return apiClient<InvoiceResponse>(`${baseUrl(companyId)}`, {
            method: 'POST',
            body: createInvoiceRequest,
        })
    },

    findAll(
        companyId: string,
        params?: {
            pagination?: PaginationQuery
            order?: InvoiceOrderQuery
            filters?: InvoiceFiltersQuery
        },
    ) {
        return apiClient<PaginatedInvoicesResponse>(`${baseUrl(companyId)}`, {
            params: {
                ...params?.pagination,
                ...params?.order,
                ...params?.filters,
            },
        })
    },

    findOne(companyId: string, invoiceId: string) {
        return apiClient<InvoiceResponse>(`${baseUrl(companyId)}/${invoiceId}`)
    },

    getTemplate(companyId: string, copyInvoiceId?: string | null) {
        return apiClient<CreateInvoiceRequest>(
            `${baseUrl(companyId)}/template`,
            {
                params: {
                    copyInvoiceId,
                },
            },
        )
    },

    update(
        companyId: string,
        invoiceId: string,
        updateInvoiceRequest: UpdateInvoiceRequest,
    ) {
        return apiClient<InvoiceResponse>(
            `${baseUrl(companyId)}/${invoiceId}`,
            {
                method: 'PUT',
                body: updateInvoiceRequest,
            },
        )
    },

    sendToEmail(
        companyId: string,
        invoiceId: string,
        email: SendEmailRequest,
        documentLabel?: DocumentLabel,
    ) {
        return apiClient<void>(`${baseUrl(companyId)}/${invoiceId}/email`, {
            method: 'POST',
            body: email,
            params: {
                documentLabel,
            },
        })
    },

    updateStatus(companyId: string, invoiceId: string, status: InvoiceStatus) {
        return apiClient<void>(`${baseUrl(companyId)}/${invoiceId}/status`, {
            method: 'PUT',
            body: { status },
        })
    },

    updateLocale(companyId: string, invoiceId: string, locale: Locale) {
        return apiClient<void>(`${baseUrl(companyId)}/${invoiceId}/locale`, {
            method: 'PUT',
            body: { locale },
        })
    },

    async downloadPdf(
        companyId: string,
        invoiceId: string,
        documentLabel?: DocumentLabel,
    ) {
        const { _data, headers } = await apiClient.raw<Blob>(
            `${baseUrl(companyId)}/${invoiceId}/pdf`,
            {
                params: {
                    documentLabel,
                },
            },
        )

        const fileName = getFileNameFromHeaders(headers)

        if (!fileName) {
            $sentry.captureMessage('File name not found in headers')
        }

        downloadFileBlob(_data!, fileName || 'invoice.pdf')
    },

    async downloadAll(
        companyId: string,
        params: {
            fileType: FileType
            variant: ExportVariant
            order?: InvoiceOrderQuery
            filters?: InvoiceFiltersQuery
        },
    ): Promise<void> {
        const { _data: blob, headers } = await apiClient.raw<Blob, 'blob'>(
            `${baseUrl(companyId)}/download`,
            {
                params: {
                    fileType: params.fileType,
                    variant: params.variant,
                    ...params?.order,
                    ...params?.filters,
                },
                responseType: 'blob',
            },
        )

        const fileName = getFileNameFromHeaders(headers)

        if (!fileName) {
            $sentry.captureMessage('File name not found in headers')
        }

        downloadFileBlob(blob!, fileName || `export.${params.fileType}`)
    },
}
