import { ofetch, type FetchContext } from 'ofetch'
import { useCookies } from '@vueuse/integrations/useCookies'
import { StatusCodes } from 'http-status-codes'
import { Locale } from '@prisma/client'
import {
    ACCESS_TOKEN_COOKIE,
    APP_LOCALE_HEADER,
    I18N_COOKIE,
    REFRESH_TOKEN_COOKIE,
} from '~/config/constants'
import { ErrorCode } from '~/types'

const API_VERSION = 'v1'
const DEBUG = false

export const apiClient = ofetch.create({
    onRequest,
    onResponse: async (context) => {
        debugResponse(context)

        const { request, response } = context
        const isUnauthorized = response.status === StatusCodes.UNAUTHORIZED

        if (!isUnauthorized) return

        const authStore = useAuthStore()
        const refreshToken = useCookies().get(REFRESH_TOKEN_COOKIE)

        if (
            response._data.data?.code === ErrorCode.invalid_access_token &&
            refreshToken
        ) {
            try {
                await authStore.refreshTokens()
                await apiClient(request, {
                    onRequest,
                    onResponse(retriedContext) {
                        Object.assign(context, retriedContext)
                    },
                })
            } catch {
                authStore.onLogout()
            }
        } else {
            authStore.onLogout()
        }
    },
})

function onRequest(ctx: FetchContext) {
    const cookies = useCookies()
    const baseURL = `/api/${API_VERSION}`
    const accessToken = cookies.get<string>(ACCESS_TOKEN_COOKIE)
    const locale = cookies.get<Locale>(I18N_COOKIE) || Locale.BG

    const headers: HeadersInit = {
        [APP_LOCALE_HEADER]: locale,
    }

    if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`
    }

    ctx.options.baseURL = baseURL
    ctx.options.params = sanitizeObject(ctx.options.params || {})
    ctx.options.headers = {
        ...ctx.options.headers,
        ...headers,
    }
}

function debugResponse({ response, options, request }: FetchContext) {
    if (!DEBUG || !response) return

    const previewResponse =
        typeof response._data === 'string'
            ? middleTruncate(response._data)
            : response._data

    console.log(
        `[${new Date().toLocaleTimeString()}]`,
        `[${options.method?.toUpperCase() || 'GET'}]`,
        (request as string).split(API_VERSION)[1],
        '\n',
        response.status < 300
            ? '✅'
            : response.status < 400
              ? '👉'
              : response.status < 500
                ? '❌'
                : '❗️',
        response.status,
        previewResponse,
    )
}

function middleTruncate(
    text: string,
    { leftLimit = 100, rightLimit = 10, ellipsis = '[...]' } = {},
) {
    if (String(text).length <= leftLimit + rightLimit) {
        return text
    }

    return [text.slice(0, leftLimit), ellipsis, text.slice(-rightLimit)].join(
        '',
    )
}
