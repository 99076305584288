export default defineNuxtRouteMiddleware((to, _from) => {
    if (to.path.startsWith('/dev')) return

    const { user, isLoggedIn, selectedCompany, hasCompanies } = useAuthStore()
    const isPublicRoute = !!to.meta.public
    const isConfirmEmailRoute = to.path === '/confirm-email'
    const isAcceptInviteRoute = to.path === '/accept-invite'
    const isUserSettingsRoute = to.path.startsWith('/settings')

    // user not logged in -> redirect to login page
    if (!isLoggedIn && !isPublicRoute) {
        return navigateTo({
            path: '/signin',
            // save the location we were at to come back later
            query: { redirect: to.fullPath },
        })
    }

    if (
        isLoggedIn &&
        !isUserSettingsRoute &&
        !isConfirmEmailRoute &&
        !isAcceptInviteRoute
    ) {
        if (!user?.isEmailConfirmed) {
            if (to.path !== '/verify-email') {
                return navigateTo('/verify-email')
            }
        }
        // 2. has companies but no company selected -> redirect to companies page
        else if (hasCompanies && !selectedCompany) {
            if (to.path !== '/companies' && to.path !== '/companies/create') {
                return navigateTo('/companies')
            }
        }
        // 3. has no companies -> redirect to create company page
        else if (!hasCompanies) {
            if (to.path !== '/companies/create') {
                return navigateTo('/companies/create')
            }
        }
        // 4. visits a public route -> redirect to home
        else if (isPublicRoute) {
            if (selectedCompany) {
                return navigateTo(companyPath('/'))
            } else {
                return navigateTo('/companies')
            }
        }
    }
})
