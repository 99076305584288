import type {
    PaginationQuery,
    RevenueOrderQuery,
    RevenueFiltersQuery,
    PaginatedRevenuesResponse,
    ExportVariant,
    FileType,
} from '~/schemas'

const baseUrl = (companyId: string) => `/companies/${companyId}/revenues`

export const revenues = {
    findAll(
        companyId: string,
        params?: {
            pagination?: PaginationQuery
            order?: RevenueOrderQuery
            filters?: RevenueFiltersQuery
        },
    ) {
        return apiClient<PaginatedRevenuesResponse>(`${baseUrl(companyId)}`, {
            params: {
                ...params?.pagination,
                ...params?.order,
                ...params?.filters,
            },
        })
    },

    async downloadAll(
        companyId: string,
        params: {
            fileType: FileType
            variant: ExportVariant
            order?: RevenueOrderQuery
            filters?: RevenueFiltersQuery
        },
    ): Promise<void> {
        const { _data: blob, headers } = await apiClient.raw<Blob, 'blob'>(
            `${baseUrl(companyId)}/download`,
            {
                params: {
                    fileType: params.fileType,
                    variant: params.variant,
                    ...params?.order,
                    ...params?.filters,
                },
                responseType: 'blob',
            },
        )

        const fileName = getFileNameFromHeaders(headers)

        if (!fileName) {
            $sentry.captureMessage('File name not found in headers')
        }

        downloadFileBlob(blob!, fileName || `export.${params.fileType}`)
    },
}
