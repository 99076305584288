<template>
    <Teleport to="body">
        <TransitionGroup
            appear
            name="list"
            tag="ul"
            aria-live="assertive"
            class="pointer-events-none fixed inset-x-0 bottom-0 z-[99] flex w-full flex-col-reverse items-center space-y-4 space-y-reverse px-4 py-6 sm:p-6"
        >
            <li
                v-for="snackbar in snackbarStore.list"
                :key="snackbar.id"
                class="pointer-events-auto flex w-full max-w-sm items-start overflow-hidden rounded-lg p-4 shadow-lg ring-1 ring-black ring-opacity-5"
                :class="colorClasses(snackbar)"
            >
                <!-- Icon -->
                <component
                    :is="snackbar.icon"
                    v-if="snackbar.icon"
                    aria-hidden="true"
                    class="mr-3 h-6 w-6 flex-shrink-0 self-center"
                />
                <!-- Text -->
                <p class="w-0 flex-1 text-base font-medium">
                    {{ snackbar.text }}
                </p>
                <!-- Close -->
                <button
                    v-if="snackbar.persistent"
                    type="button"
                    class="ml-4 inline-flex flex-shrink-0 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    @click="snackbarStore.hide(snackbar)"
                >
                    <span class="sr-only">{{ t('dismiss') }}</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                </button>
            </li>
        </TransitionGroup>
    </Teleport>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline'

const { t } = useI18n()
const snackbarStore = useSnackbarStore()

function colorClasses(snackbar: Snackbar) {
    switch (snackbar.type) {
        case 'success':
            return 'bg-green-50 text-green-900'
        case 'error':
            return 'bg-red-50 text-red-900'
        case 'warning':
            return 'bg-yellow-50 text-yellow-900'
        case 'info':
            return 'bg-blue-50 text-blue-900'
    }
}
</script>
