import { useCookies } from '@vueuse/integrations/useCookies'
import { ACCESS_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE } from '~/config/constants'

export default defineNuxtPlugin(async (_nuxtApp) => {
    const authStore = useAuthStore()
    const cookies = useCookies([ACCESS_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE])
    const accessToken = cookies.get(ACCESS_TOKEN_COOKIE)
    const refreshToken = cookies.get(REFRESH_TOKEN_COOKIE)

    try {
        if (accessToken) {
            await authStore.fetchAuthUser()
        } else if (refreshToken) {
            await authStore.refreshTokens()
            await authStore.fetchAuthUser()
        }
    } catch {
        await authStore.onLogout()
    }
})
