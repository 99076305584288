import type {
    BankAccountResponse,
    CreateBankAccountRequest,
    UpdateBankAccountRequest,
} from '~/schemas'

const baseUrl = (companyId: string) => `/companies/${companyId}/bank-accounts`

export const bankAccounts = {
    create(
        companyId: string,
        createBankAccountRequest: CreateBankAccountRequest,
    ) {
        return apiClient<BankAccountResponse>(baseUrl(companyId), {
            method: 'POST',
            body: createBankAccountRequest,
        })
    },

    findAll(companyId: string) {
        return apiClient<BankAccountResponse[]>(baseUrl(companyId))
    },

    findOne(companyId: string, bankAccountId: string) {
        return apiClient<BankAccountResponse>(
            `${baseUrl(companyId)}/${bankAccountId}`,
        )
    },

    update(
        companyId: string,
        bankAccountId: string,
        updateBankAccountRequest: UpdateBankAccountRequest,
    ) {
        return apiClient<BankAccountResponse>(
            `${baseUrl(companyId)}/${bankAccountId}`,
            {
                method: 'PUT',
                body: updateBankAccountRequest,
            },
        )
    },

    remove(companyId: string, bankAccountId: string) {
        return apiClient<BankAccountResponse>(
            `${baseUrl(companyId)}/${bankAccountId}`,
            {
                method: 'DELETE',
            },
        )
    },

    setDefault(companyId: string, bankAccountId: string) {
        return apiClient<void>(`${baseUrl(companyId)}/set-default`, {
            method: 'POST',
            body: { bankAccountId },
        })
    },
}
