import type {
    ProformaInvoiceResponse,
    CreateProformaInvoiceRequest,
    UpdateProformaInvoiceRequest,
    PaginatedProformaInvoicesResponse,
    ProformaInvoiceFiltersQuery,
    ProformaInvoiceOrderQuery,
    SendEmailRequest,
    ProformaInvoiceStatus,
    DocumentLabel,
    PaginationQuery,
    ExportVariant,
    FileType,
} from '~/schemas'
import type { Locale } from '@prisma/client'

const baseUrl = (companyId: string) =>
    `/companies/${companyId}/proforma-invoices`

export const proformaInvoices = {
    create(
        companyId: string,
        createProformaInvoiceRequest: CreateProformaInvoiceRequest,
    ) {
        return apiClient<ProformaInvoiceResponse>(`${baseUrl(companyId)}`, {
            method: 'POST',
            body: createProformaInvoiceRequest,
        })
    },

    findAll(
        companyId: string,
        params?: {
            pagination?: PaginationQuery
            order?: ProformaInvoiceOrderQuery
            filters?: ProformaInvoiceFiltersQuery
        },
    ) {
        return apiClient<PaginatedProformaInvoicesResponse>(
            `${baseUrl(companyId)}`,
            {
                params: {
                    ...params?.pagination,
                    ...params?.order,
                    ...params?.filters,
                },
            },
        )
    },

    findOne(companyId: string, proformaInvoiceId: string) {
        return apiClient<ProformaInvoiceResponse>(
            `${baseUrl(companyId)}/${proformaInvoiceId}`,
        )
    },

    getTemplate(companyId: string, copyProformaInvoiceId?: string | null) {
        return apiClient<CreateProformaInvoiceRequest>(
            `${baseUrl(companyId)}/template`,
            {
                params: {
                    copyProformaInvoiceId,
                },
            },
        )
    },

    update(
        companyId: string,
        proformaInvoiceId: string,
        updateInvoiceRequest: UpdateProformaInvoiceRequest,
    ) {
        return apiClient<ProformaInvoiceResponse>(
            `${baseUrl(companyId)}/${proformaInvoiceId}`,
            {
                method: 'PUT',
                body: updateInvoiceRequest,
            },
        )
    },

    sendToEmail(
        companyId: string,
        proformaInvoiceId: string,
        email: SendEmailRequest,
        documentLabel?: DocumentLabel,
    ) {
        return apiClient<void>(
            `${baseUrl(companyId)}/${proformaInvoiceId}/email`,
            {
                method: 'POST',
                body: email,
                params: {
                    documentLabel,
                },
            },
        )
    },

    updateStatus(
        companyId: string,
        proformaInvoiceId: string,
        status: ProformaInvoiceStatus,
    ) {
        return apiClient<void>(
            `${baseUrl(companyId)}/${proformaInvoiceId}/status`,
            {
                method: 'PUT',
                body: { status },
            },
        )
    },

    updateLocale(companyId: string, proformaInvoiceId: string, locale: Locale) {
        return apiClient<void>(
            `${baseUrl(companyId)}/${proformaInvoiceId}/locale`,
            {
                method: 'PUT',
                body: { locale },
            },
        )
    },

    async downloadPdf(
        companyId: string,
        proformaInvoiceId: string,
        documentLabel?: DocumentLabel,
    ) {
        const { _data, headers } = await apiClient.raw<Blob>(
            `${baseUrl(companyId)}/${proformaInvoiceId}/pdf`,
            {
                params: {
                    documentLabel,
                },
            },
        )

        const fileName = getFileNameFromHeaders(headers)

        if (!fileName) {
            $sentry.captureMessage('File name not found in headers')
        }

        downloadFileBlob(_data!, fileName || 'proforma-invoice.pdf')
    },

    async downloadAll(
        companyId: string,
        params: {
            fileType: FileType
            variant: ExportVariant
            order?: ProformaInvoiceOrderQuery
            filters?: ProformaInvoiceFiltersQuery
        },
    ): Promise<void> {
        const { _data: blob, headers } = await apiClient.raw<Blob, 'blob'>(
            `${baseUrl(companyId)}/download`,
            {
                params: {
                    fileType: params.fileType,
                    variant: params.variant,
                    ...params?.order,
                    ...params?.filters,
                },
                responseType: 'blob',
            },
        )

        const fileName = getFileNameFromHeaders(headers)

        if (!fileName) {
            $sentry.captureMessage('File name not found in headers')
        }

        downloadFileBlob(blob!, fileName || `export.${params.fileType}`)
    },
}
