import { nanoid } from 'nanoid/non-secure'
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
} from '@heroicons/vue/24/outline'
import type { Component } from 'vue'

export type SnackbarOptions = {
    text: string
    type: 'success' | 'error' | 'warning' | 'info'
    persistent?: boolean
    icon?: Component | boolean
}
export type Snackbar = Required<SnackbarOptions & { id: string }>

const icons: Record<Snackbar['type'], Snackbar['icon']> = {
    success: CheckCircleIcon,
    error: ExclamationCircleIcon,
    warning: ExclamationTriangleIcon,
    info: InformationCircleIcon,
}

const DURATION = 5000

export const useSnackbarStore = defineStore('snackbar', {
    state: () => ({
        list: [] as Snackbar[],
    }),

    actions: {
        show({
            type,
            icon = icons[type],
            text,
            persistent = false,
        }: SnackbarOptions) {
            const snackbar: Snackbar = {
                id: nanoid(),
                type,
                icon,
                text,
                persistent,
            }

            this.list.push(snackbar)

            if (!persistent) {
                setTimeout(() => this.hide(snackbar), DURATION)
            }
        },

        hide(snackbar: Snackbar) {
            this.list.splice(this.list.indexOf(snackbar), 1)
        },
    },
})
