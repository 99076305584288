import type {
    CheckVatRequest,
    CheckVatResponse,
    CountryVatStatusResponse,
} from '~/schemas'

const baseUrl = () => '/vat-validation'

export const vatValidation = {
    checkVatNumber(checkVatRequest: CheckVatRequest) {
        return apiClient<CheckVatResponse>(`${baseUrl()}/check-vat-number`, {
            method: 'POST',
            body: checkVatRequest,
        })
    },

    checkCountryStatus() {
        return apiClient<CountryVatStatusResponse>(`${baseUrl()}/check-status`)
    },
}
