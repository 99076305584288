export default defineNuxtRouteMiddleware((to, _from) => {
    const { selectedCompany } = useAuthStore()
    const requiredRole = to.meta.role

    if (requiredRole && selectedCompany) {
        if (Array.isArray(requiredRole)) {
            if (!requiredRole.includes(selectedCompany?.role)) {
                return abortNavigation()
            }
        } else {
            if (requiredRole !== selectedCompany?.role) {
                return abortNavigation()
            }
        }
    }
})
