import type {
    CreateUserRequest,
    TokensResponse,
    CreateUserByInviteRequest,
    ConfirmEmailRequest,
    LoginRequest,
    RefreshTokensRequest,
    ForgotPasswordRequest,
    ResetPasswordRequest,
    ResendEmailConfirmationRequest,
    InvitationResponse,
    AcceptInviteRequest,
} from '~/schemas'

const baseUrl = () => '/auth'

export const auth = {
    register(createUserRequest: CreateUserRequest) {
        return apiClient<TokensResponse>(`${baseUrl()}/register`, {
            method: 'POST',
            body: createUserRequest,
        })
    },

    registerByInvite(createUserByInviteRequest: CreateUserByInviteRequest) {
        return apiClient<TokensResponse>(`${baseUrl()}/register-by-invite`, {
            method: 'POST',
            body: createUserByInviteRequest,
        })
    },

    confirmEmail(confirmEmailRequest: ConfirmEmailRequest) {
        return apiClient<TokensResponse>(`${baseUrl()}/confirm-email`, {
            method: 'POST',
            body: confirmEmailRequest,
        })
    },

    resendConfirmationLink(
        resendEmailConfirmationRequest: ResendEmailConfirmationRequest,
    ) {
        return apiClient<void>(`${baseUrl()}/resend-confirmation-link`, {
            method: 'POST',
            body: resendEmailConfirmationRequest,
        })
    },

    login(loginRequest: LoginRequest) {
        return apiClient<TokensResponse>(`${baseUrl()}/login`, {
            method: 'POST',
            body: loginRequest,
        })
    },

    refreshTokens(refreshTokensRequest: RefreshTokensRequest) {
        return apiClient<TokensResponse>(`${baseUrl()}/refresh`, {
            method: 'POST',
            body: refreshTokensRequest,
        })
    },

    forgotPassword(forgotPasswordRequest: ForgotPasswordRequest) {
        return apiClient<void>(`${baseUrl()}/forgot-password`, {
            method: 'POST',
            body: forgotPasswordRequest,
        })
    },

    resetPassword(resetPasswordRequest: ResetPasswordRequest) {
        return apiClient<TokensResponse>(`${baseUrl()}/reset-password`, {
            method: 'POST',
            body: resetPasswordRequest,
        })
    },

    getInvitation(token: string) {
        return apiClient<InvitationResponse>(`${baseUrl()}/invitation/${token}`)
    },

    acceptInvite(acceptInviteRequest: AcceptInviteRequest) {
        return apiClient<void>(`${baseUrl()}/accept-invite`, {
            method: 'POST',
            body: acceptInviteRequest,
        })
    },
}
