import type {
    UserResponse,
    UpdateUserRequest,
    ChangePasswordRequest,
} from '~/schemas'
import type { Locale } from '@prisma/client'

const baseUrl = () => `/me`

export const me = {
    getProfile() {
        return apiClient<UserResponse>(`${baseUrl()}`)
    },

    updateProfile(updateUserRequest: UpdateUserRequest) {
        return apiClient<UserResponse>(`${baseUrl()}`, {
            method: 'PATCH',
            body: updateUserRequest,
        })
    },

    changePassword(changePasswordRequest: ChangePasswordRequest) {
        return apiClient<UserResponse>(`${baseUrl()}/credentials`, {
            method: 'PUT',
            body: changePasswordRequest,
        })
    },

    selectCompany(companyId: string) {
        return apiClient<void>(`${baseUrl()}/select-company`, {
            method: 'PUT',
            body: { companyId },
        })
    },

    selectLocale(locale: Locale) {
        return apiClient<void>(`${baseUrl()}/select-locale`, {
            method: 'PUT',
            body: { locale },
        })
    },

    deleteAccount() {
        return apiClient<void>(baseUrl(), {
            method: 'DELETE',
        })
    },
}
