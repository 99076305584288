import type {
    ItemResponse,
    CreateItemRequest,
    UpdateItemRequest,
    PaginatedItemsResponse,
    ItemFiltersQuery,
    ItemOrderQuery,
    PaginationQuery,
} from '~/schemas'

const baseUrl = (companyId: string) => `/companies/${companyId}/items`

export const items = {
    create(companyId: string, createItemRequest: CreateItemRequest) {
        return apiClient<ItemResponse>(baseUrl(companyId), {
            method: 'POST',
            body: createItemRequest,
        })
    },

    findAll(
        companyId: string,
        params?: {
            pagination?: PaginationQuery
            order?: ItemOrderQuery
            filters?: ItemFiltersQuery
        },
    ) {
        return apiClient<PaginatedItemsResponse>(baseUrl(companyId), {
            params: {
                ...params?.pagination,
                ...params?.order,
                ...params?.filters,
            },
        })
    },

    findOne(companyId: string, itemId: string) {
        return apiClient<ItemResponse>(`${baseUrl(companyId)}/${itemId}`)
    },

    getTemplate(companyId: string) {
        return apiClient<CreateItemRequest>(`${baseUrl(companyId)}/template`)
    },

    update(
        companyId: string,
        itemId: string,
        updateItemRequest: UpdateItemRequest,
    ) {
        return apiClient<ItemResponse>(`${baseUrl(companyId)}/${itemId}`, {
            method: 'PUT',
            body: updateItemRequest,
        })
    },

    remove(companyId: string, itemId: string) {
        return apiClient<ItemResponse>(`${baseUrl(companyId)}/${itemId}`, {
            method: 'DELETE',
        })
    },

    removeMany(companyId: string, ids?: string[]) {
        return apiClient<void>(`${baseUrl(companyId)}/items`, {
            method: 'DELETE',
            params: { ids },
        })
    },
}
