import type { ExchangeRateResponse } from '~/schemas'

export const exchangeRate = {
    getExchangeRate(
        baseCurrency: string,
        targetCurrency: string,
        date: string,
    ) {
        return apiClient<ExchangeRateResponse>('/exchange-rate', {
            query: {
                baseCurrency,
                targetCurrency,
                date,
            },
        })
    },
}
