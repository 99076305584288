import type {
    CompanyResponse,
    CompanySettingsResponse,
    CreateCompanyRequest,
    FileResponse,
    NextDocumentNumberResponse,
    UpdateCompanyRequest,
    UpdateCompanySettingsRequest,
} from '~/schemas'
import type { RevenueDocumentType } from '@prisma/client'

const baseUrl = () => '/companies'

export const companies = {
    create(createCompanyRequest: CreateCompanyRequest) {
        return apiClient<CompanyResponse>(`${baseUrl()}`, {
            method: 'POST',
            body: createCompanyRequest,
        })
    },

    findAll() {
        return apiClient<CompanyResponse[]>(`${baseUrl()}`)
    },

    findOne(companyId: string) {
        return apiClient<CompanyResponse>(`${baseUrl()}/${companyId}`)
    },

    update(companyId: string, updateCompanyRequest: UpdateCompanyRequest) {
        return apiClient<CompanyResponse>(`${baseUrl()}/${companyId}`, {
            method: 'PATCH',
            body: updateCompanyRequest,
        })
    },

    remove(companyId: string) {
        return apiClient<CompanyResponse>(`${baseUrl()}/${companyId}`, {
            method: 'DELETE',
        })
    },

    getCompanySettings(companyId: string) {
        return apiClient<CompanySettingsResponse>(
            `${baseUrl()}/${companyId}/settings`,
        )
    },

    updateCompanySettings(
        companyId: string,
        updateCompanySettingsRequest: UpdateCompanySettingsRequest,
    ) {
        return apiClient<CompanySettingsResponse>(
            `${baseUrl()}/${companyId}/settings`,
            {
                method: 'PATCH',
                body: updateCompanySettingsRequest,
            },
        )
    },

    uploadLogo(companyId: string, logo: File) {
        const formData = new FormData()
        formData.append('logo', logo)

        return apiClient<FileResponse>(`${baseUrl()}/${companyId}/logo`, {
            method: 'PUT',
            body: formData,
        })
    },

    removeLogo(companyId: string) {
        return apiClient<void>(`${baseUrl()}/${companyId}/logo`, {
            method: 'DELETE',
        })
    },

    nextDocumentNumber(
        companyId: string,
        documentType: RevenueDocumentType,
        prefix: string,
    ) {
        return apiClient<NextDocumentNumberResponse>(
            `${baseUrl()}/${companyId}/next-document-number`,
            {
                query: {
                    documentType,
                    prefix,
                },
            },
        )
    },
}
