import type {
    CreditNoteResponse,
    UpdateCreditNoteRequest,
    SendEmailRequest,
    DocumentLabel,
    PaginationQuery,
    CreateCreditNoteRequest,
    CreditNoteOrderQuery,
    DebitNoteFiltersQuery,
    PaginatedCreditNotesResponse,
    CreditNoteStatus,
    ExportVariant,
    FileType,
    InvoiceFiltersQuery,
    InvoiceOrderQuery,
} from '~/schemas'
import type { Locale } from '@prisma/client'

const baseUrl = (companyId: string) => `/companies/${companyId}/credit-notes`

export const creditNotes = {
    create(
        companyId: string,
        createCreditNoteRequest: CreateCreditNoteRequest,
    ): Promise<CreditNoteResponse> {
        return apiClient(`${baseUrl(companyId)}`, {
            method: 'POST',
            body: createCreditNoteRequest,
        })
    },

    findAll(
        companyId: string,
        params?: {
            pagination?: PaginationQuery
            order?: CreditNoteOrderQuery
            filters?: DebitNoteFiltersQuery
        },
    ): Promise<PaginatedCreditNotesResponse> {
        return apiClient(`${baseUrl(companyId)}`, {
            params: {
                ...params?.pagination,
                ...params?.order,
                ...params?.filters,
            },
        })
    },

    findOne(
        companyId: string,
        creditNoteId: string,
    ): Promise<CreditNoteResponse> {
        return apiClient(`${baseUrl(companyId)}/${creditNoteId}`)
    },

    getTemplate(companyId: string): Promise<CreateCreditNoteRequest> {
        return apiClient(`${baseUrl(companyId)}/template`)
    },

    update(
        companyId: string,
        creditNoteId: string,
        updateCreditNoteRequest: UpdateCreditNoteRequest,
    ): Promise<CreditNoteResponse> {
        return apiClient(`${baseUrl(companyId)}/${creditNoteId}`, {
            method: 'PUT',
            body: updateCreditNoteRequest,
        })
    },

    sendToEmail(
        companyId: string,
        creditNoteId: string,
        email: SendEmailRequest,
        documentLabel?: DocumentLabel,
    ) {
        return apiClient(`${baseUrl(companyId)}/${creditNoteId}/email`, {
            method: 'POST',
            body: email,
            params: {
                documentLabel,
            },
        })
    },

    updateStatus(
        companyId: string,
        creditNoteId: string,
        status: CreditNoteStatus,
    ): Promise<CreditNoteResponse> {
        return apiClient(`${baseUrl(companyId)}/${creditNoteId}/status`, {
            method: 'PUT',
            body: { status },
        })
    },

    updateLocale(companyId: string, creditNoteId: string, locale: Locale) {
        return apiClient<void>(`${baseUrl(companyId)}/${creditNoteId}/locale`, {
            method: 'PUT',
            body: { locale },
        })
    },

    async downloadPdf(
        companyId: string,
        creditNoteId: string,
        documentLabel?: DocumentLabel,
    ) {
        const { _data, headers } = await apiClient.raw<Blob>(
            `${baseUrl(companyId)}/${creditNoteId}/pdf`,
            {
                params: {
                    documentLabel,
                },
            },
        )

        const fileName = getFileNameFromHeaders(headers)

        if (!fileName) {
            $sentry.captureMessage('File name not found in headers')
        }

        downloadFileBlob(_data!, fileName || 'credit-note.pdf')
    },

    async downloadAll(
        companyId: string,
        params: {
            fileType: FileType
            variant: ExportVariant
            order?: InvoiceOrderQuery
            filters?: InvoiceFiltersQuery
        },
    ): Promise<void> {
        const { _data: blob, headers } = await apiClient.raw<Blob, 'blob'>(
            `${baseUrl(companyId)}/download`,
            {
                params: {
                    fileType: params.fileType,
                    variant: params.variant,
                    ...params?.order,
                    ...params?.filters,
                },
                responseType: 'blob',
            },
        )

        const fileName = getFileNameFromHeaders(headers)

        if (!fileName) {
            $sentry.captureMessage('File name not found in headers')
        }

        downloadFileBlob(blob!, fileName || `export.${params.fileType}`)
    },
}
