import { useCookies } from '@vueuse/integrations/useCookies'
import type {
    UserResponse,
    CreateUserRequest,
    CreateUserByInviteRequest,
    LoginRequest,
    TokensResponse,
    CompanyResponse,
} from '~/schemas'
import { ACCESS_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE } from '~/config/constants'

const cookies = useCookies([ACCESS_TOKEN_COOKIE, REFRESH_TOKEN_COOKIE])

function setTokenCookies({ accessToken, refreshToken }: TokensResponse) {
    cookies.set(ACCESS_TOKEN_COOKIE, accessToken, {
        path: '/',
        maxAge: 60 * 60 * 24, // 1 day
    })
    cookies.set(REFRESH_TOKEN_COOKIE, refreshToken, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365, // 1 year
    })
}

function removeTokenCookies() {
    cookies.remove(ACCESS_TOKEN_COOKIE)
    cookies.remove(REFRESH_TOKEN_COOKIE)
}

export const useAuthStore = defineStore('auth', {
    state: () => ({
        user: null as UserResponse | null,
    }),

    getters: {
        isLoggedIn: (state) => {
            return !!state.user
        },
        fullName: (state) => {
            return state.user
                ? `${state.user.firstName} ${state.user.lastName}`
                : ''
        },
        selectedCompany: (state): CompanyResponse | null => {
            return state.user?.companies.find((c) => c.selected) ?? null
        },
        hasCompanies: (state): boolean => {
            return (
                !!state.user?.companies.length ||
                !!state.user?.invitations.length
            )
        },
    },

    actions: {
        async login(loginRequest: LoginRequest) {
            const tokens = await $api.auth.login(loginRequest)
            await this.onLogin(tokens)
        },

        async register(createUserRequest: CreateUserRequest) {
            const tokens = await $api.auth.register(createUserRequest)
            await this.onLogin(tokens)
        },

        async registerByInvite(createUserRequest: CreateUserByInviteRequest) {
            const tokens = await $api.auth.registerByInvite(createUserRequest)
            await this.onLogin(tokens)
        },

        async refreshTokens() {
            const refreshToken = cookies.get(REFRESH_TOKEN_COOKIE)
            if (!refreshToken) {
                throw new Error('No refresh token')
            }

            const tokens = await $api.auth.refreshTokens({
                refreshToken,
            })

            setTokenCookies(tokens)
        },

        async fetchAuthUser() {
            this.user = await $api.me.getProfile()
        },

        async onLogin(tokens: TokensResponse) {
            setTokenCookies(tokens)

            await this.fetchAuthUser()
        },

        async onLogout() {
            removeTokenCookies()
            this.$reset()

            await navigateTo('/signin')
        },
    },
})
