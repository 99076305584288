import type {
    InviteUserRequest,
    InvitationResponse,
    CompanyUserResponse,
    UpdateCompanyUserRequest,
} from '~/schemas'

const baseUrl = (companyId: string) => `/companies/${companyId}/users`

export const users = {
    invite(
        companyId: string,
        inviteUserRequest: InviteUserRequest,
        resend?: boolean,
    ) {
        return apiClient<InvitationResponse>(baseUrl(companyId), {
            method: 'POST',
            body: inviteUserRequest,
            query: { resend },
        })
    },

    findAll(companyId: string) {
        return apiClient<CompanyUserResponse[]>(baseUrl(companyId))
    },

    findOne(companyId: string, userId: string) {
        return apiClient<CompanyUserResponse>(`${baseUrl(companyId)}/${userId}`)
    },

    update(
        companyId: string,
        userId: string,
        updateCompanyUserRequest: UpdateCompanyUserRequest,
    ) {
        return apiClient<CompanyUserResponse>(
            `${baseUrl(companyId)}/${userId}`,
            {
                method: 'PUT',
                body: updateCompanyUserRequest,
            },
        )
    },

    remove(companyId: string, userId: string) {
        return apiClient<void>(`${baseUrl(companyId)}/${userId}`, {
            method: 'DELETE',
        })
    },

    cancelInvite(companyId: string, email: string) {
        return apiClient<void>(`${baseUrl(companyId)}/invitations/${email}`, {
            method: 'DELETE',
        })
    },
}
