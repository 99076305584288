import type {
    ContactResponse,
    CreateContactRequest,
    UpdateContactRequest,
    PaginatedContactsResponse,
    ContactFiltersQuery,
    ContactOrderQuery,
    PaginationQuery,
} from '~/schemas'

const baseUrl = (companyId: string) => `/companies/${companyId}/contacts`

export const contacts = {
    create(companyId: string, createContactRequest: CreateContactRequest) {
        return apiClient<ContactResponse>(baseUrl(companyId), {
            method: 'POST',
            body: createContactRequest,
        })
    },

    findAll(
        companyId: string,
        params?: {
            pagination?: PaginationQuery
            order?: ContactOrderQuery
            filters?: ContactFiltersQuery
        },
    ) {
        return apiClient<PaginatedContactsResponse>(baseUrl(companyId), {
            params: {
                ...params?.pagination,
                ...params?.order,
                ...params?.filters,
            },
        })
    },

    findOne(companyId: string, contactId: string) {
        return apiClient<ContactResponse>(`${baseUrl(companyId)}/${contactId}`)
    },

    getTemplate(companyId: string) {
        return apiClient<CreateContactRequest>(`${baseUrl(companyId)}/template`)
    },

    update(
        companyId: string,
        contactId: string,
        updateContactRequest: UpdateContactRequest,
    ) {
        return apiClient<ContactResponse>(
            `${baseUrl(companyId)}/${contactId}`,
            {
                method: 'PUT',
                body: updateContactRequest,
            },
        )
    },

    remove(companyId: string, contactId: string) {
        return apiClient<ContactResponse>(
            `${baseUrl(companyId)}/${contactId}`,
            {
                method: 'DELETE',
            },
        )
    },

    removeMany(companyId: string, ids?: string[]) {
        return apiClient<void>(`${baseUrl(companyId)}`, {
            method: 'DELETE',
            params: { ids },
        })
    },
}
