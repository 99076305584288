export function companyPath(path: string) {
    const authStore = useAuthStore()
    const companySlug = authStore.selectedCompany?.slug

    if (!companySlug) return ''

    if (path === '/') path = ''

    return `/${companySlug}${path}`
}
