import { ZodIssueCode, setErrorMap } from 'zod'
import type { ZodErrorMap } from 'zod'
import type { Composer } from 'vue-i18n'

export default defineNuxtPlugin((nuxtApp) => {
    const { t } = nuxtApp.$i18n as Composer

    const customErrorMap: ZodErrorMap = (issue, ctx) => {
        if (issue.code === ZodIssueCode.invalid_type) {
            const nullishValues = ['undefined', 'null', 'string']

            if (nullishValues.includes(issue.received)) {
                switch (issue.expected) {
                    case 'array':
                        return {
                            message: t('validation.at-least-one-required'),
                        }
                }

                return { message: t('validation.required') }
            }
        }

        if (issue.code === ZodIssueCode.invalid_string) {
            switch (issue.validation) {
                case 'email':
                    return { message: t('validation.invalid-email') }
                case 'uuid':
                    return { message: t('validation.required') }
            }
        }

        if (issue.code === ZodIssueCode.too_small) {
            switch (issue.type) {
                case 'string':
                    return {
                        message:
                            issue.minimum === 1
                                ? t('validation.required')
                                : t('validation.required-min-chars', {
                                      minCharacters: issue.minimum,
                                  }),
                    }
                case 'number':
                    return {
                        message: issue.inclusive
                            ? t('validation.min-amount', {
                                  minAmount: issue.minimum,
                              })
                            : t('validation.more-than', {
                                  moreThan: issue.minimum,
                              }),
                    }
                case 'array':
                    return {
                        message: t('validation.at-least-one-required'),
                    }
            }
        }

        if (issue.code === ZodIssueCode.custom) {
            const params = issue.params || {}

            if (params.translationKey) {
                return {
                    message: t(
                        params.translationKey,
                        params.translationOptions,
                    ),
                }
            }
        }

        $sentry.captureMessage(
            `Zod validation missing translation: ${issue.code}`,
            {
                extra: {
                    issue,
                    ctx,
                },
            },
        )

        return { message: ctx.defaultError }
    }

    setErrorMap(customErrorMap)
})
