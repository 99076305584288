export const useAppStore = defineStore('app', () => {
    const route = useRoute()
    const authStore = useAuthStore()
    const sidebarStorageState = useLocalStorage('sidebar', true)

    const desktopSidebarOpen = ref(false)
    const mobileSidebarOpen = ref(false)

    const hideSidebar = ref(false)
    const isSidebarAllowed = computed(() => {
        return (
            route.meta.layout !== 'auth' &&
            !!authStore.selectedCompany &&
            !hideSidebar.value
        )
    })

    function toggleSidebar() {
        sidebarStorageState.value = !sidebarStorageState.value

        breakpoint.lg.value
            ? (desktopSidebarOpen.value = !desktopSidebarOpen.value)
            : (mobileSidebarOpen.value = !mobileSidebarOpen.value)
    }

    return {
        desktopSidebarOpen,
        mobileSidebarOpen,
        sidebarStorageState,
        hideSidebar,
        isSidebarAllowed,
        toggleSidebar,
    }
})
